import { HashRouter, Routes, Route } from "react-router-dom";
import "swiper/css";
import ScrollToTop from "./component/layout/scrolltop";
import "./global.css";
import TawkMessengerReactUmd from "@tawk.to/tawk-messenger-react";
import React, { useEffect, Suspense } from "react";
import { CircularProgress } from "@mui/material";
import { WalletProvider } from "./hooks/useWeb3Wallet";

const HomePage = React.lazy(() => import("./pages/hometwo"));
const StairwayDonationPage = React.lazy(() =>
  import("./pages/stairway-donation")
);
const CommunityBenefitsPage = React.lazy(() =>
  import("./pages/community-benfits")
);
const DisclaimerPage = React.lazy(() => import("./pages/disclaimer"));
const GreatestRiskOfFailurePage = React.lazy(() =>
  import("./pages/greatest-risk-of-failure")
);
const GeneralTermsOfUsePage = React.lazy(() =>
  import("./pages/general-terms-of-use")
);
const EndUserLicenseAgreementPage = React.lazy(() =>
  import("./pages/end-user-license-agreement")
);

function App() {
  console.log('deployed!')
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "auto",
        autoDisplay: false,
        includedLanguages: "ara,fr,de,hi,it,ja,ko,pt,es",
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);

    window.googleTranslateElementInit = googleTranslateElementInit;


    

    var middlewareScript = document.createElement("script");

    middlewareScript.innerHTML = `
    if (window.Middleware){
        Middleware.track({
            serviceName:"Barnaje Homepage",
            projectName:"Barnaje Homepage",
            accountKey:"yndckmrsqlgydnfwhmqpdkspscopmnukhqmg",
            target:"https://jedro1270.middleware.io",
        })
    }
  `;

    document.body.appendChild(middlewareScript);
  }, []);

  return (
    <div className="App">
      <WalletProvider>
        <TawkMessengerReactUmd
          propertyId="64c76c0d94cf5d49dc677748"
          widgetId="1h6lfs63o"
        />
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </div>
          }
        >
          <HashRouter>
            <ScrollToTop />
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route
                path="/stairway-donation-plan"
                element={<StairwayDonationPage />}
              />
              <Route
                path="/community-benefits"
                element={<CommunityBenefitsPage />}
              />
              <Route
                path="/disclaimer-and-mutual-legal-assertations"
                element={<DisclaimerPage />}
              />
              <Route
                path="/greatest-risk-of-failure"
                element={<GreatestRiskOfFailurePage />}
              />
              <Route
                path="/general-terms-of-use-and-privacy-policy"
                element={<GeneralTermsOfUsePage />}
              />
              <Route
                path="/end-user-license-agreement"
                element={<EndUserLicenseAgreementPage />}
              />
            </Routes>
          </HashRouter>
        </Suspense>
      </WalletProvider>
    </div>
  );
}

export default App;
