import axios from "axios";
import { apiUrl, dashboardUrl, registerUrl } from "../constants/urls";

const AuthServiceInstance = axios.create({
  baseURL: `${apiUrl}/authentication`,
  headers: {
    "Content-Type": "application/json",
    "API-Key": process.env.REACT_APP_API_KEY,
  },
});

AuthServiceInstance.interceptors.response.use(
  (response) => {
    console.log("[AuthService] response of service", response);
    return (window.location = dashboardUrl); // TODO: Change to final position
  },
  (error) => {
    console.log(error);

    if (error.response.data.message === "User not found") {
      const currentUrlParams = new URLSearchParams(window.location.search);
      
      const newUrl = new URL(registerUrl);
      newUrl.search = currentUrlParams.toString();

      return window.location.assign(newUrl.toString());
    }

    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

const login = (walletAddress) => {
  return new Promise((resolve, reject) => {
    AuthServiceInstance.post(`/login`, { walletAddress })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(_handleError(error));
        }
      )
      .catch((error) => {
        reject(_handleError(error));
      });
  });
};

const register = (userData) => {
  return new Promise((resolve, reject) => {
    AuthServiceInstance.post("/register", userData)
      .then(
        (response) => resolve(response),
        (error) => reject(_handleError(error))
      )
      .catch((error) => reject(_handleError(error)));
  });
};

const _handleError = (error) => {
  // var errorCode = error.code;
  var errorMessage = error.message;
  console.log("[Error authentication]", errorMessage);
  return errorMessage;
};

export { login, register };
