// hooks/useWallet.js
import { useState, useEffect, createContext, useContext } from "react";
import {
  init,
  useAccountCenter,
  useConnectWallet,
  useSetChain,
} from "@web3-onboard/react";
import injectedModule, { ProviderLabel } from "@web3-onboard/injected-wallets";
import { ethers } from "ethers";
import walletConnectModule from "@web3-onboard/walletconnect";
import useWindowSize from "./useWindowSize";
import { login } from "../services/AuthService";

const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [userInitiatedConnection, setUserInitiatedConnection] = useState(false);

  return (
    <WalletContext.Provider
      value={{ userInitiatedConnection, setUserInitiatedConnection }}
    >
      {children}
    </WalletContext.Provider>
  );
};

const apiKey = "180475d0-fc1c-4352-9e7d-3adf195da616";

const injected = injectedModule({
  displayUnavailable: [
    ProviderLabel.MetaMask,
    ProviderLabel.Trust,
    ProviderLabel.OKXWallet,
    ProviderLabel.Exodus,
    ProviderLabel.Brave,
  ],
});
const walletConnect = walletConnectModule({
  projectId: "706a67d762fa8df463f3aaaed8349d85",
  dappUrl: "https://barnajedao.com/",
  requiredChains: [56],
  optionalChains: [97],
});

const testChainId = "0x61"; // BSC Testnet
const mainChainId = "0x38"; // BSC Mainnet
const localChainId = "31337"; // BSC Local

const chainId = mainChainId; // BSC Mainnet

// initialize Onboard
init({
  apiKey,
  wallets: [injected, walletConnect],
  chains: [
    {
      id: mainChainId,
      token: "BNB",
      label: "Binance",
      rpcUrl: "https://bsc-dataseed.binance.org/",
    },
    // {
    //   id: testChainId,
    //   token: "BNB Testnet",
    //   label: "Binance Test Net",
    //   rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    // },
    // {
    //   id: localChainId,
    //   token: "BNB",
    //   label: "Binance Localhost",
    //   rpcUrl: "http://127.0.0.1:8545/",
    // },
  ],
  appMetadata: {
    name: "Barnaje",
    logo: "assets/images/logo/logo.svg",
    icon: "assets/images/logo/Icon-Purple.fw.png",
    description:
      "Our exclusive global community platform is a place where unity fuels personal success!",
  },
  connect: {
    autoConnectLastWallet: true,
  },
});

export default function useWeb3Wallet({
  setLoading = () => {},
  manageDisconnect = false,
} = {}) {
  const { userInitiatedConnection, setUserInitiatedConnection } =
    useContext(WalletContext);
  const windowSize = useWindowSize();

  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const updateAccountCenter = useAccountCenter();
  const [
    {
      chains, // the list of chains that web3-onboard was initialized with
      connectedChain, // the current chain the user's wallet is connected to
      settingChain, // boolean indicating if the chain is in the process of being set
    },
    setChain, // function to call to initiate user to switch chains in their wallet
  ] = useSetChain();

  const [account, setAccount] = useState(null);

  let ethersProvider;

  const handleConnect = () => {
    setUserInitiatedConnection(true);
    connect();
  };

  useEffect(() => {
    if (wallet) {
      if (connectedChain?.id !== chainId) {
        setChain({
          chainId,
          token: "BNB",
          label:
            chainId === mainChainId
              ? "Binance"
              : chainId === testChainId
              ? "Binance Test Net"
              : "Binance Localhost",
          rpcUrl:
            chainId === mainChainId
              ? "https://bsc-dataseed.binance.org/"
              : chainId === testChainId
              ? "https://data-seed-prebsc-1-s1.binance.org:8545/"
              : "http://127.0.0.1:8545/",
        });
      }

      ethersProvider = new ethers.providers.Web3Provider(
        wallet.provider,
        "any"
      );

      if (wallet?.provider) {
        const { name, avatar } = wallet?.accounts[0].ens ?? {};
        setAccount({
          address: wallet.accounts[0].address,
          balance: wallet.accounts[0].balance,
          ens: { name, avatar: avatar?.url },
        });
      }
    }
  }, [wallet]);

  useEffect(() => {
    if (wallet && !userInitiatedConnection && manageDisconnect) {
      if (wallet) {
        disconnect(wallet);
      }
    }
  }, [wallet, userInitiatedConnection, disconnect, manageDisconnect]);

  useEffect(() => {
    updateAccountCenter({
      minimal: windowSize.innerWidth <= 992 ? true : false,
      position: "topRight",
    });
  }, [windowSize]);

  useEffect(() => {
    if (connectedChain?.id === chainId && userInitiatedConnection) {
      setLoading(true);

      localStorage.setItem("walletAddress", wallet?.accounts[0].address);
      localStorage.setItem("walletLogo", wallet?.icon);

      login(wallet.accounts[0].address);
    }
  }, [connectedChain, userInitiatedConnection]);

  useEffect(() => {
    return () => {
      setUserInitiatedConnection(false);
    };
  }, []);

  return {
    wallet,
    connecting,
    connect: handleConnect,
    disconnect,
    account,
    setAccount,
    ethersProvider,
  };
}
